export const filesContainer = {
  margin: '2em 0',
  display: 'inline-flex',
  flexFlow: 'column nowrap',

  '& .upload-container': {
    padding: '1.5em 0',
    width: '25rem',
    maxWidth: '100%',
    marginBottom: '1em',
  },

  '& .upload-files-buttons-container': {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
