import { getText, getDynamicTranslation } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { theme } from 'styles';
import { textLinkStyle } from './styles';

export const getColumns = (options) => [
  {
    name: getText('order'),
    value: 'order',
    width: 100,
  },
  {
    name: getText('dealTitle'),
    render: (record) => (
      <Button type="link" linkTo={`/deal/${record.id}`} css={textLinkStyle}>
        {getDynamicTranslation(record.title)?.title}
      </Button>
    ),
  },
  {
    name: getText('actions'),
    width: 80,
    flex: '0 1 auto',
    render: (item) => (
      <>
        <Icon iconName="las la-pen" size={20} onClick={() => options.onEdit(item)} />
        <Icon
          iconName="las la-trash"
          size={20}
          onClick={() => options.onDelete(item)}
          style={{ color: theme.red, marginLeft: 10 }}
        />
      </>
    ),
  },
];
