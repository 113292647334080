import { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { useTranslations } from '@veraio/strank';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import Localization from 'components/layouts/LocalizationPanel';
import { Icon } from 'components/ui';
import { Button } from 'components/UIExternal';
import Routes from 'enums/Routes';
import logo from 'assets/images/Logo_white.png';
import {
  logoImage,
  drawerWrapper,
  sidebarContainer,
  translationsSideBarStyles,
  hamburgerMenu,
  logoAndHamburgerStyles,
} from './styles';

const NavigationPanel = () => {
  const { getText } = useTranslations();
  const isAdmin = hasRole(USER_ROLES.DS_ADMIN);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleToggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const menuItems = [
    {
      key: '1',
      icon: <Icon iconName="las la-th-large" />,
      label: <Link to={Routes.HomePage}>{getText('dashboard')}</Link>,
    },
    {
      key: '2',
      icon: <Icon iconName="las la-shopping-bag" />,
      label: <Link to={`${Routes.Deals}`}>{getText('deals')}</Link>,
    },
    {
      key: '3',
      icon: <Icon iconName="las la-ticket-alt" />,
      label: <Link to={Routes.Orders}>{getText('Orders')}</Link>,
    },
    {
      key: '4',
      icon: <Icon iconName="las la-comment-alt" />,
      label: <Link to={Routes.Disputes}>{getText('disputes')}</Link>,
    },
    {
      key: '6',
      icon: <Icon iconName="las la-wallet" />,
      label: <Link to={Routes.Businesses}>{getText('businesses')}</Link>,
    },
    {
      key: '7',
      icon: <Icon iconName="las la-map-marker" />,
      label: <Link to={Routes.Addresses}>{getText('addresses')}</Link>,

    },
    ...(isAdmin
      ? [
        {
          key: '8',
          icon: <Icon iconName="las la-shield-alt" size={18} />,
          label: <Link to={Routes.Managers}>{getText('managers')}</Link>,
        },
        {
          key: '12',
          icon: <Icon iconName="las la-cash-register" size={20} />,
          label: <Link to={Routes.TransactionsList}>{getText('financials')}</Link>,
        },
        {
          key: '16',
          icon: <Icon iconName="las la-bullhorn" size={20} />,
          label: <Link to={Routes.Marketing}>{getText('marketing')}</Link>,
        },
        {
          key: '13',
          icon: <Icon iconName="las la-cog" size={20} />,
          label: <Link to={`${Routes.Settings}/general`}>{getText('settings')}</Link>,
        },
      ]
      : []),
    {
      key: '10',
      icon: <Icon iconName="lar la-bell" />,
      label: <Link to={Routes.Notifications}>{getText('notifications')}</Link>,
    },
    {
      key: '11',
      icon: <Icon iconName="las la-chart-pie" size={20} />,
      label: <Link to={Routes.Statistics}>{getText('stats')}</Link>,
    },
    {
      key: '14',
      icon: <Icon iconName="las la-globe" />,
      label: <Localization />,
    },
    {
      key: '15',
      icon: <Icon iconName="las la-angle-double-right" />,
      label: (
        <a href={applicationUrls.strank} target="_blank" css={translationsSideBarStyles} rel="noreferrer">
          {getText('manageWebsiteTranslations')}
        </a>
      ),
    },
  ];

  return (
    <aside css={drawerWrapper(isSidebarOpen)}>
      <div css={logoAndHamburgerStyles}>
        <Button small type="link" onClick={handleToggleSidebar} css={hamburgerMenu}>
          <Icon iconName="las la-bars" />
        </Button>
        <Button small type="link" linkTo={Routes.HomePage}>
          <img css={logoImage} src={logo} alt="Logo" />
        </Button>
      </div>
      <div css={sidebarContainer}>
        <Menu mode="inline" items={menuItems} />
      </div>
    </aside>
  );
};

NavigationPanel.propTypes = {
  isVisible: PropTypes.bool,
  toggleNavigationDrawer: PropTypes.func,
};

export default NavigationPanel;
