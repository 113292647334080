import { theme } from 'styles';

export const container = {
  padding: '0 2em',
};

export const pageContainer = {
  padding: '2em 0',
};

export const textLinkStyle = {
	color: theme.primary_blue,
	whiteSpace: 'normal',
	padding: 0,
	'&:hover': {
		borderColor: 'transparent',
		color: theme.primary_blue,
	},
};

export const actionStyle = {
	display: 'flex',
	color: `${theme.primary_blue} !important`,
	fontSize: '1.25rem !important',
	padding: 0,

	'&:hover': {
		borderColor: 'transparent',
		opacity: 1,
		color: theme.primary_blue,
	},
	'& :focus ': { color: theme.primary_blue },
};
