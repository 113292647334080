export default {
  // private routes
  Deals: '/deals',
  Orders: '/orders',
  Businesses: '/businesses',
  Addresses: '/addresses',
  Refunds: '/refunds',
  Disputes: '/disputes',
  DealDetail: '/deal/:dealId',
  Managers: '/managers',
  DisputeDetail: '/dispute/:disputeId',
  RefundDetail: '/refund/:refundId',
  AddressDetail: '/address/:addressId',
  BusinessDetail: '/business/:businessId',
  Marketing: '/marketing',
  Messages: '/messages',
  StartChat: '/messages/start-chat',
  OngoingChat: '/messages/chat',
  Settings: '/settings',
  SettingsCountryConfiguration: '/settings/country-configurations',
  Notifications: '/notifications',
  CreateNotification: '/notifications/create',
  NotificationDetail: '/notifications/:notificationId',
  Statistics: '/statistics',
  Transactions: '/financials',
  TransactionsList: '/financials/transactions',
  TransactionsClearing: '/financials/clearing',
  TransactionsSettlement: '/financials/settlement',
  TransactionBundledTransactions: '/financials/settlement/:transactionId/bundled-transactions',
  TransactionDetails: '/financials/transactions/:transactionId/details',

  // public routes
  RedirectCallback: '/authorization-callback',
  Logout: '/logout',
  HomePage: '/',
};
