import apiRoutes from 'config/apiRoutes';
import { deleteReq, patchReq } from '../axios/makeRequest';

import { postReq } from '../axios/makeRequest';

const baseUrl = `${apiRoutes.ADDRESS}/api/Media`;

export const createDealMedia = (data, dealId) => postReq(`${baseUrl}/AddImage/${dealId}`, data);

export const removeDealMedia = (dealId, reason) =>
  deleteReq(`${apiRoutes.MEDIA_REMOVE(dealId)}${reason && `?reason=${reason}`}`);

export const updateDealMediaVisibility = (dealId, isHidden) =>
  patchReq(`${baseUrl}/HideDealMedia/${dealId}`, { isHidden });
