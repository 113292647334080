import { useRef } from 'react';
import { useTranslations } from '@veraio/strank';
import toast from 'react-hot-toast';
import { Table, useUrlParams } from 'components/UIExternal';
import { PageHeader } from 'components/shared';
import { EditDealOrderPopup } from 'components/screens/Marketing';
import { getRecommendedDeals, removeFromRecommendedDeals, setError, updateRecommendedDealsOrder } from 'services';
import { container } from './styles';
import { getColumns } from './config';

const MarketingScreen = () => {
  const { queryParams } = useUrlParams();
  const { getText } = useTranslations();
  const tableRef = useRef();
  const modalRef = useRef();

  const fetchRecommendedDeals = async (options) => {
    const [res, error] = await getRecommendedDeals(options);

    if (error) return setError(error);

    const mappedItems = res.items.map((deal, index) => ({
      order: (queryParams?.pageNumber - 1) * queryParams?.pageSize + index + 1,
      ...deal,
    }));

    return [{ ...res, items: mappedItems }, error];
  };

  const handleOpenEditDealOrderPopup = (data) => modalRef.current.open({ ...data });

  const handleUpdateRecommendOrder = async (deal, newDealOrderIndex) => {
    await updateRecommendedDealsOrder(deal.id, newDealOrderIndex);

    modalRef.current.close();
    tableRef.current.refreshData();
  };

  const handleClose = () => modalRef.current.close();

  const handleDelete = async (dealId) => {
    await removeFromRecommendedDeals(dealId);
    toast.success(getText('successfullyRemovedFromRecommended'));
    tableRef.current.refreshData();
  };

  return (
    <div css={container}>
      <PageHeader title={getText('recommendedDeals')} />

      <Table
        ref={tableRef}
        columns={getColumns({
          onEdit: (data) => handleOpenEditDealOrderPopup(data),
          onDelete: (data) => handleDelete(data.id),
        })}
        getDataMethod={fetchRecommendedDeals}
      />

      <EditDealOrderPopup ref={modalRef} onSubmit={handleUpdateRecommendOrder} onClose={handleClose} />
    </div>
  );
};

export default MarketingScreen;
