import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { isArray, isFunction, isNil, isNumber } from '@veraio/core';
import { Upload } from 'antd';
import { toast } from 'react-hot-toast';
import { Button, Icon } from 'components';
import { setError, createDealMedia } from 'services';
import { filesContainer } from './styles';

export const UploadDealImage = ({ onChange, allowedFormats, sizeLimit, dealId, recommendation }) => {
  const { getText } = useTranslations();
  const [fileInfo, setFileInfo] = useState({ file: null, isUploaded: null });
  const maxSize = isNumber(sizeLimit) && sizeLimit > 0 ? sizeLimit : 1000;

  const fileValidation = (file) => {
    if (file.size > maxSize) {
      toast.error(`${file.name} ${getText('uploadFailedFileSizeBigger')}`);
      return Upload.LIST_IGNORE;
    }

    if (isArray(allowedFormats) && !allowedFormats.includes(file.type)) {
      toast.error(`${file.name} ${getText('uploadFailedWrongFormat')}`);
      return Upload.LIST_IGNORE;
    }

    setFileInfo({ file, isUploaded: true });
  };

  const handleUpload = async () => {
    const fileData = new FormData();
    fileData.append('file', fileInfo.file);
    const [res, err] = await createDealMedia(fileData, dealId);
    err ? setError(err) : toast.success(getText('fileUploadedSuccessfully'));

    handleCancel();
    !isNil(res) && isFunction(onChange) && onChange(res);
  };

  const handleCancel = () => setFileInfo({ file: null, isUploaded: false });

  const fileUploadConfig = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: (isArray(allowedFormats) ? allowedFormats : []).join(','),
    beforeUpload: fileValidation,
  };

  return (
    <section css={filesContainer}>
      <Upload.Dragger {...fileUploadConfig} disabled={fileInfo?.isUploaded} className="upload-container">
        <Icon
          iconName={`las la-7x ${fileInfo.isUploaded ? 'la-check-circle' : 'la-cloud-upload-alt'}`}
          className="upload-files-icon"
        />
        <p className="upload-text">{getText(fileInfo.isUploaded ? 'uploadSuccessful' : 'dragAndDropBrowseFiles')}</p>
        {fileInfo.isUploaded && <p className="upload-text">{fileInfo.file.name}</p>}
        {!!recommendation && <p>{recommendation}</p>}
      </Upload.Dragger>
      <div className="upload-files-buttons-container">
        <Button
          small
          type="default"
          onClick={handleCancel}
          disabled={!fileInfo.isUploaded}
          className="upload-files-button">
          {getText('cancel')}
        </Button>
        <Button small onClick={handleUpload} disabled={!fileInfo.isUploaded} className="upload-files-button">
          {getText('submit')}
        </Button>
      </div>
    </section>
  );
};

UploadDealImage.propTypes = {
  onChange: PropTypes.func,
  allowedFormats: PropTypes.array,
  dealId: PropTypes.number,
  recommendation: PropTypes.string,
  sizeLimit: PropTypes.number,
};
