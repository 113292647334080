import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { getDynamicTranslation, useTranslations } from '@veraio/strank';
import { isFunction } from '@veraio/core';
import { TextInput } from 'components/ui';
import { Button, Modal } from 'components/UIExternal';
import { modalActions } from './styles';

const EditDealOrderPopup = forwardRef(({ onSubmit, onClose }, ref) => {
	const { getText } = useTranslations();
	const modalRef = useRef();
	const [deal, setDeal] = useState();
	const [updatedOrder, setUpdatedOrder] = useState();

	useImperativeHandle(ref, () => ({
		open: (dealToEdit) => {
			modalRef.current.open();
			setDeal(dealToEdit);
			setUpdatedOrder(dealToEdit.order);
		},
		close: () => modalRef.current.close(),
	}));

	const handleCancel = () => {
		setDeal(null);
		setUpdatedOrder(null);
		isFunction(onClose) && onClose();
	};

	const handleUpdateOrder = (value) => setUpdatedOrder(Number(value));

	const handleSubmit = async () => isFunction(onSubmit) && onSubmit(deal, updatedOrder);

	return (
		<Modal
			ref={modalRef}
			modalTitle={getText('editDealOrder')}
		>
			<p>{`${getText('set')} "${deal && getDynamicTranslation(deal?.title)?.title}" ${getText('recommendedOrdersIndex')}`}</p>
			<br />
			<TextInput
				defaultValue={updatedOrder?.toString()}
				onSearch={handleUpdateOrder}
			/>

			<div css={modalActions}>
				<Button type="link" small onClick={handleCancel}>
					{getText('cancel')}
				</Button>
				<Button type="secondary" small onClick={handleSubmit}>
					{getText('saveChanges')}
				</Button>
			</div>
		</Modal>
	);
});

export default EditDealOrderPopup;