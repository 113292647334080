import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Tooltip } from 'antd';
import ReactPlayer from 'react-player';
import VideoThumbnail from 'react-video-thumbnail';
import QRCode from 'qrcode';
import { toast } from 'react-hot-toast';
import { useTranslations } from '@veraio/strank';
import { CheckButton, useDeepEffect, CommentModal, Button } from 'components';
import { ImageGalleryPanel, Icon } from 'components/ui';
import { UploadDealImage } from 'components/screens';
import { dealImageProperties } from 'enums';
import MediaTypesEnum from 'enums/MediaTypesEnum';
import useError from 'services/errorHandling/useError';
import { removeDealMedia, updateDealMediaVisibility } from 'services/api/mediaService';
import { galleryModal, video, youTubePlayIcon, dealImages, dealImgContainer, qrCodeContainer } from './styles';

export const DealImagesTab = ({ deal }) => {
  const { media, id: dealId } = deal ?? {};
  const { getText } = useTranslations();
  const { setError } = useError();
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [images, setImages] = useState(media);
  const [imagesForPreview, setImagesForPreview] = useState([]);
  const [selectedImgIndex, setSelectedImgIndex] = useState(0);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const imageToDeleteRef = useRef();
  const [qrCode, setQrCode] = useState(null);

  useDeepEffect(() => {
    generateQR();
    getMapImagesForPreview();
  }, [images]);

  const generateQR = async () => {
    const res = await QRCode.toDataURL(`${applicationUrls.dealshakerPortal}/deal/${deal.name}`);
    res && setQrCode(res);
  };

  const getMapImagesForPreview = () => {
    const newMedia = images.map((item) => {
      if (item.typeId === MediaTypesEnum.Link.id) {
        return {
          renderItem: () => renderVideo(`www.youtube.com/watch?v=${item.url}`),
          original: item.url,
          thumbnail: item.thumbnailUrl || item.url,
        };
      }
      if (item.typeId === MediaTypesEnum.Video.id) {
        return {
          renderItem: () => renderVideo(item.url),
          renderThumbInner: () => renderThumbnail(item.url),
          original: item.url,
          thumbnail: item.thumbnailUrl || item.url,
        };
      }
      return {
        thumbnail: item.thumbnailUrl || item.url,
        original: item.url,
      };
    });
    setImagesForPreview(newMedia);
  };

  const handleOpenDeleteModal = (img) => {
    imageToDeleteRef.current = img.id;
    setIsCommentModalVisible(true);
  };

  const handleRemoveImage = async (reason) => {
    const [, err] = await removeDealMedia(imageToDeleteRef.current, reason);
    if (err) return setError(err);

    toast.success(getText('fileDeleteSuccessfully'));
    setIsCommentModalVisible(false);
    setImages((prev) => prev.filter((img) => img?.id !== imageToDeleteRef.current));
  };

  const handleVisibilityChange = async (img) => {
    const [, err] = await updateDealMediaVisibility(img?.id, !img?.isHidden);
    if (err) setError(err);

    toast.success(getText('visibilityIsChangedSuccessfully'));
    setImages((prev) => prev.map((imgEl) => (imgEl.id === img.id ? { ...imgEl, isHidden: !img.isHidden } : imgEl)));
  };

  const handleUpload = (newImage) => setImages((prevImages) => [newImage, ...prevImages]);

  const handlePreviewImages = (dealMedia) => {
    const imgIndex = images.map((image) => image.id).indexOf(dealMedia.id);
    setSelectedImgIndex(imgIndex > -1 ? imgIndex : 0);
    setIsPreviewModalVisible(true);
  };

  const handleDownloadQrCode = () => {
    const link = document.createElement('a');
    link.href = qrCode;
    link.download = deal.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderVideo = (url) => <ReactPlayer controls css={video} url={url} />;

  const renderThumbnail = (url) => (
    <div css={youTubePlayIcon}>
      <Icon iconName="las la-play" />
      <VideoThumbnail height={60} width={90} videoUrl={url} />
    </div>
  );

  return (
    <section>
      <h3>{getText('qrCodeDeal')}</h3>
      {!!qrCode && (
        <div css={qrCodeContainer}>
          <img src={qrCode} alt="qr" />
          <Button small type="secondary" onClick={handleDownloadQrCode}>
            <Icon icon="las la-download" size={15} />
            {getText('downloadQR')}
          </Button>
        </div>
      )}
      <h3>{getText('dealImages')}</h3>
      <UploadDealImage
        onChange={handleUpload}
        allowedFormats={dealImageProperties.allowedFormats}
        sizeLimit={dealImageProperties.sizeLimit}
        dealId={dealId}
      />
      <div css={dealImages}>
        {images?.map((img) => (
          <div key={img.id} css={dealImgContainer(img.isHidden)}>
            <div className="overlay">
              <Icon iconName="las la-eye" size="1.3rem" onClick={() => handlePreviewImages(img)} />
              <Icon iconName="las la-trash" size="1.3rem" onClick={() => handleOpenDeleteModal(img)} />
            </div>
            <img src={img.url} alt="deal-img" />
            <CheckButton checked={!img.isHidden} onChange={() => handleVisibilityChange(img)}>
              <span className="text-label">{getText('visibleOnPublicPage')}</span>
              <Tooltip title={getText('hiddenImageHelp')} placement="right">
                <Icon iconName="las la-info-circle" size="1.3rem" />
                {/* in order to trigger the tooltip, the icon should be followed by a space character  */}
                &nbsp;
              </Tooltip>
            </CheckButton>
          </div>
        ))}
        <Modal
          centered
          width={1300}
          css={galleryModal}
          footer={null}
          open={isPreviewModalVisible}
          destroyOnClose
          onCancel={() => setIsPreviewModalVisible(false)}>
          <ImageGalleryPanel showNav startIndex={selectedImgIndex} items={imagesForPreview} />
        </Modal>
        <CommentModal
          title={getText('removePhoto')}
          isVisible={isCommentModalVisible}
          onCancel={() => setIsCommentModalVisible(false)}
          onAccept={handleRemoveImage}
        />
      </div>
    </section>
  );
};

DealImagesTab.propTypes = {
  deal: PropTypes.object,
};
