import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import ImgCrop from 'antd-img-crop';
import { Avatar, Col, Input, message, Modal, Row, Spin, Upload } from 'antd';
import { useTranslations } from '@veraio/strank';
import { getToken } from '@oneecosystem/authenticate';
import { Button } from 'components/UIExternal';
import { NestedCol, ImageGalleryPanel, Icon } from 'components/ui';
import { useUser } from 'stores/contexts/User';
import apiRoutes from 'config/apiRoutes';
import MediaTypesEnum from 'enums/MediaTypesEnum';
import { formatTime } from 'utils/valueFormatter';
import { delay } from 'utils/queryUtils';
import useError from 'services/errorHandling/useError';
import { getReq } from 'services/axios/makeRequest';
import { sendMessage, startChatWithMessage } from 'services/api/chatService';
import { removeDealMedia } from 'services/api/mediaService';
import startConversationImg from 'assets/images/chat/start-conversation.png';
import {
  allMessagesWrapper,
  imagesPreview,
  messageWrapper,
  uploadBtn,
  noMessagesBox,
  loadMoreBtn,
  galleryModal,
} from './styles';

const Messages = ({ metaData, chatId }) => {
  const history = useHistory();
  const { getText } = useTranslations();
  const { setError } = useError();
  const [images, setImages] = useState([]);
  const [chat, setChat] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [isCalled, setIsCalled] = useState(false);
  const [noMessages, setNoMessages] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [noMoreMessages, setNoMoreMessages] = useState(false);
  const [startImgToPreview, setStartImageToPreview] = useState(0);
  const [imagesToPreview, setImagesToPreview] = useState([]);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const { userInfo: currentUser, isAuthenticated } = useUser();
  const today = new Date();

  const getChatId = () =>
    window.location.search.includes('chatId') ? chatId || window.location.search.split('=')[1] || false : false;
  const [rawChatData, setRawChatData] = useState(getChatId() ? metaData?.messages || [] : []);

  useEffect(() => {
    const interval = setInterval(() => {
      !isCalled && getChatId() && isAuthenticated && getNewChat();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setChat(getTransformedChatData(rawChatData, metaData));
  }, [rawChatData]);

  useEffect(() => {
    if (!chatId) {
      setRawChatData(metaData?.messages || []);
      setNoMessages(!metaData?.messages?.length);
      setIsCalled(true);
    }
  }, [metaData]);

  const mergedRawDataLast = (data, rawData) => [
    ...rawData,
    ...data.filter((item) => !rawData.some((i) => i.id === item.id)),
  ];

  const mergedRawDataFirst = (data, rawData) => [
    ...data.filter((item) => !rawData.some((i) => i.id === item.id)),
    ...rawData,
  ];

  const getNewChat = async (pageNum = 1, size = 5) => {
    setIsCalled(true);
    await getReq(`${apiRoutes.CHAT_GET(chatId || window.location.search.split('=')[1])}`, {
      pageNumber: pageNum,
      pageSize: size,
    }).then((res) => {
      if (res[0]?.length) {
        setRawChatData((prev) => mergedRawDataFirst(res[0], prev));
        setNoMessages(false);
      } else setNoMessages(true);
      setIsCalled(false);
    });
  };

  const loadHistory = async (pageNum = 1, size = 5) => {
    await getReq(`${apiRoutes.CHAT_GET(chatId)}`, {
      pageNumber: pageNum,
      pageSize: size,
    }).then((res) => {
      res[0]?.length && setRawChatData((prev) => mergedRawDataLast(res[0], prev));
      res[0]?.length < 5 && setNoMoreMessages(true);
    });
  };

  const getMapImages = (imagesArray) =>
    imagesArray.map((image) => ({
      uid: image?.id,
      url: image?.url,
      thumbUrl: image?.thumbnailUrl,
      name: image?.id,
      status: 'done',
    }));

  const getTransformedChatData = (chatData, metaDataRes) => {
    const groups = [];

    const addUserGroup = (foundGroup, meta, newMessage) => {
      foundGroup.messages.push({
        user: {
          id: meta.id,
          name: `${meta.firstName} ${meta.lastName}`,
          avatarUrl: meta.avatarThumbnailUrl,
        },
        date: newMessage.createDate,
        messages: [{ text: newMessage.text, media: getMapImages(newMessage.media) }],
      });
    };

    chatData.forEach((newMessage) => {
      const meta = newMessage.userId === currentUser?.id ? currentUser : metaDataRes?.participants[newMessage?.userId];
      const foundGroup = groups.find((res) =>
        compareOnlyDate(getOnlyDatePart(res.date), getOnlyDatePart(newMessage.createDate)),
      );
      if (foundGroup) {
        if (foundGroup.messages) {
          const msg = foundGroup.messages[foundGroup.messages.length - 1];
          if (newMessage.userId === msg.user.id)
            msg.messages.push({ text: newMessage.text, media: getMapImages(newMessage.media) });
          else addUserGroup(foundGroup, meta, message);
          return;
        }
        addUserGroup(foundGroup, meta, message);
        return;
      }
      const group = {
        date: newMessage.createDate,
        messages: [
          {
            user: {
              id: meta?.id,
              name: `${meta?.firstName} ${meta?.lastName}`,
              avatarUrl: meta?.avatarThumbnailUrl,
            },
            date: newMessage.createDate,
            messages: [{ text: newMessage.text, media: getMapImages(newMessage.media) }],
          },
        ],
      };
      groups.push(group);
    });
    return groups;
  };

  const getOnlyDatePart = (dateString) => {
    const d = new Date(dateString);
    d.setHours(0, 0, 0, 0);
    return d;
  };

  const compareOnlyDate = (first, second) =>
    first.getDate() === second.getDate() && first.getYear() === second.getYear() && first.getDay() === second.getDay();

  const onSendMessage = () => {
    if (messageText.length || images.length) {
      chatId
        ? sendMessage(
            chatId,
            messageText,
            images.map((img) => img.id),
            setError,
          )
        : metaData.participants &&
          startChatWithMessage(
            {
              text: messageText,
              media: images.map((img) => img.id),
              participants: Object.keys(metaData.participants),
            },
            setError,
          ).then((res) => {
            if (res) history.push(`chat?chatId=${res}`);
          });
    }
    setMessageText('');
    setImages([]);
  };

  const onRemoveImage = async (id) => {
    setDeleteInProgress(true);
    const [, err] = await removeDealMedia(id);
    if (err) return setError(err);

    setImages(images.filter((img) => img?.id !== id));
    setDeleteInProgress(false);
  };

  const imgUploadProps = {
    name: 'file',
    multiple: true,
    showUploadList: false,
    accept: MediaTypesEnum.Image.allowedTypes.join(', '),
    action: apiRoutes.MEDIA_ADD_IMAGE,
    headers: { Authorization: `Bearer ${getToken().access_token}` },
    beforeUpload: (file) => {
      if (file.size > MediaTypesEnum.Image.allowedSize) {
        message.error(`${file.name} ${getText('uploadFailedFileSizeBigger')}`);
        return Upload.LIST_IGNORE;
      }
      if (!MediaTypesEnum.Image.allowedTypes.includes(file.type)) {
        message.error(`${file.name} ${getText('uploadFailedWrongFormat')}`);
        return Upload.LIST_IGNORE;
      }
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') setImages([...images, info.file.response]);
      if (status === 'done') message.success(`${info.file.name} ${getText('fileUploadedSuccessfully')}`);
      else if (status === 'error') message.error(`${info.file.name} ${getText('fileUploadedFailed')}`);
    },
  };

  const mapMessages = () => (
    <Col span={24} lg={24} css={allMessagesWrapper}>
      {noMessages ? (
        <div css={noMessagesBox}>
          <img src={startConversationImg} alt="Start chat" />
          <p className="start">{getText('startConversation')}</p>
          <p className="ask-anything">{getText('askAnything')}</p>
          <hr />
        </div>
      ) : (
        chat?.map((msgGrp, groupIndex) => (
          <Row key={groupIndex}>
            {msgGrp?.messages?.map((msg, i) => {
              const isCurrentUser = msg.user.id === currentUser.id;
              return (
                <Col
                  key={i}
                  css={messageWrapper(isCurrentUser)}
                  lg={isCurrentUser ? { span: 16, offset: 8 } : 16}
                  span={24}>
                  <div css={`flex space-between ${isCurrentUser && 'reverse-avatar'}`}>
                    <div css={isCurrentUser && 'reverse-avatar'}>
                      <Avatar src={msg.user.avatarUrl}>{msg.user.name?.charAt(0)}</Avatar>
                      <label className="user-name-label">{isCurrentUser ? getText('you') : msg.user.name}</label>
                    </div>
                    <label className="hour-label">{formatTime(msg.date)}</label>
                  </div>
                  <div className="reverse">
                    {msg.messages.map((mess, index) => (
                      <div key={index} className="flex flex-column">
                        {mess.text.length ? <label className="message">{mess.text}</label> : <></>}
                        {mess.media.length ? (
                          <ImgCrop data-automation-id="Messages-fb92b8bf-5cf5-4378-83ab-0d3141f2e2c4">
                            <Upload
                              showUploadList={{
                                showRemoveIcon: false,
                              }}
                              data-automation-id="Messages-2f30509a-1305-4d71-ba41-2a30a1c8d416"
                              css={imagesPreview}
                              className="image"
                              onPreview={(e) => {
                                setStartImageToPreview(mess.media.findIndex((img) => img.uid === e.uid));
                                setImagesToPreview(
                                  mess.media.map((media) => ({ original: media.url, thumbnail: media.thumbUrl })),
                                );
                                setIsPreviewModalVisible(true);
                              }}
                              listType="picture-card"
                              fileList={mess.media}
                            />
                          </ImgCrop>
                        ) : (
                          <></>
                        )}
                      </div>
                    ))}
                  </div>
                </Col>
              );
            })}
            <NestedCol lg={17} span={24} totalWidth={17}>
              <label className="date-label">{`${
                compareOnlyDate(today, new Date(msgGrp.date)) ? getText('today') : ''
              } ${moment(msgGrp.date).format('DD MMMM')}`}</label>
              <hr />
            </NestedCol>
          </Row>
        ))
      )}
      {!noMessages && (
        <Button
          type="secondary"
          disabled={noMoreMessages}
          small
          css={loadMoreBtn}
          onClick={() => delay(() => loadHistory(pageNumber + 1).then(() => setPageNumber((prev) => prev + 1)))}>
          {noMoreMessages ? getText('noMoreMessages') : getText('loadMore')}
        </Button>
      )}
    </Col>
  );

  return (
    <>
      <Row gutter={24}>
        <NestedCol totalWidth={17} span={24} lg={17}>
          <div>
            {chat.length || noMessages ? (
              mapMessages()
            ) : (
              <div className="content-container">
                <Spin size="large" />
              </div>
            )}
          </div>
        </NestedCol>
        <NestedCol lg={11} span={24} totalWidth={17}>
          <Input.TextArea
            onFocus={() => metaData?.chatId?.length && history.push(`/messages/chat?chatId=${metaData.chatId}`)}
            value={messageText}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                onSendMessage();
              }
            }}
            onChange={(e) => setMessageText(e.target.value)}
            autoSize
            style={{ minHeight: 170, marginBottom: 16 }}
          />
          <Row style={{ marginBottom: 120 }} gutter={[24, 16]}>
            <NestedCol lg={5} span={24} totalWidth={11}>
              <Upload {...imgUploadProps}>
                <Button type="link" small css={uploadBtn}>
                  <Icon iconName="las la-paperclip" />
                  <div className="flex-column">
                    <label className="click-here-lbl">{getText('clickHereToUploadAttachment')}</label>
                    <label className="attach-text-lbl">{getText('youCanAttachJpg')}</label>
                  </div>
                </Button>
              </Upload>
            </NestedCol>
            <NestedCol lg={6} span={24} totalWidth={11}>
              <Button
                type="secondary"
                disabled={!messageText.length && !images?.length}
                small
                css={loadMoreBtn}
                onClick={onSendMessage}>
                {getText('send')}
              </Button>
            </NestedCol>
          </Row>
        </NestedCol>
        <NestedCol lg={6} span={24} totalWidth={17}>
          {deleteInProgress ? (
            <Spin size="large" />
          ) : images.length ? (
            <ImgCrop>
              <Upload
                css={imagesPreview}
                onPreview={(e) => {
                  setStartImageToPreview(images.findIndex((img) => img.id === e.uid));
                  setImagesToPreview(images.map((media) => ({ original: media.url, thumbnail: media.thumbnailUrl })));
                  setIsPreviewModalVisible(true);
                }}
                listType="picture-card"
                fileList={getMapImages(images)}
                onRemove={(e) => onRemoveImage(e.uid)}
              />
            </ImgCrop>
          ) : (
            <></>
          )}
        </NestedCol>
      </Row>
      <Modal
        centered
        width={1300}
        className={galleryModal}
        footer={null}
        open={isPreviewModalVisible}
        destroyOnClose
        onCancel={() => setIsPreviewModalVisible(false)}>
        <ImageGalleryPanel showNav startIndex={startImgToPreview} items={imagesToPreview} />
      </Modal>
    </>
  );
};

Messages.propTypes = {
  metaData: PropTypes.object,
  chatId: PropTypes.string,
};

export default Messages;
